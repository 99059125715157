/* Change this file to get your personal Porfolio */

const settings = {
  isSplash: false, // Change this to true if you want to use the splash screen.
  useCustomCursor: true, // Change this to false if you want the good'ol cursor
  googleTrackingID: "UA-174238252-2",
};

//Home Page
const greeting = {
  title: "Hello.",
  title2: "Hrishi",
  logo_name: "Buğra Şener",
  nickname: "hrishi1999 / picleric",
  full_name: "Buğra Şener",
  subTitle:
    "Almanca öğretmeni ve bass gitaristlik yapıyorum. Size de bu yolda yardımcı olmak istiyorum.",
  resumeLink: "",
  mail: "",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  linkedin:
    "https://www.linkedin.com/in/ahmet-bu%C4%9Fra-%C5%9Fener-428a30310?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
  gmail: "ahmetbugrasener0@gmail.com",
  gitlab: " ",
  facebook: " ",
  twitter: "",
  instagram: "https://www.instagram.com/bugrasener2?igsh=MWM3ZWh0eW1uNnNveQ==",
};

const skills = {
  data: [
    {
      title: "Almanca Özel Dersleri",
      fileName: "FullStackImg",
      skills: [
        "⚡ Özel takipli her yaşa özel Almanca dersi.",
        "⚡ Online veya dilediğiniz yerden ders imkanı.",
        "⚡ Son keşfedilden öğretme teknikleriyle 7'den 70'e uygunluk",
      ],
      softwareSkills: [
        {
          skillName: "Etkili Materyaller",
          fontAwesomeClassname: "simple-icons:gitbook",
          style: {},
        },
        {
          skillName: "Online Dersler",
          fontAwesomeClassname: "simple-icons:zoom",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#0B5CFF",
          },
        },
        {
          skillName: "Speaking Denemeleri",
          fontAwesomeClassname: "simple-icons:wechat",
          style: {
            color: "#07C160",
          },
        },
        {
          skillName: "Testler ve Ödevler",
          fontAwesomeClassname: "simple-icons:reacthookform",
          style: {
            color: "#EC5990",
          },
        },
      ],
    },

    {
      title: "İngilizce Özel Dersleri",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Uzun yıllar tecrübe",
        "⚡ C2 seviyesinde İngilizceyle ileri derece eğitim",
        "⚡ Başarı garantili her yaşa uygun dersler",
      ],
      softwareSkills: [
        {
          skillName: "Etkili Materyaller",
          fontAwesomeClassname: "simple-icons:gitbook",
          style: {},
        },
        {
          skillName: "Online Dersler",
          fontAwesomeClassname: "simple-icons:zoom",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#0B5CFF",
          },
        },
        {
          skillName: "Speaking Denemeleri",
          fontAwesomeClassname: "simple-icons:wechat",
          style: {
            color: "#07C160",
          },
        },
        {
          skillName: "Testler ve Ödevler",
          fontAwesomeClassname: "simple-icons:reacthookform",
          style: {
            color: "#EC5990",
          },
        },
      ],
    },
    {
      title: "Bass Gitar Dersleri ",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Yılların tecrübesiyle bilgi aktarımı",
        "⚡ Online veya yüz yüze dersler",
        "⚡ Çeşitli gruplarda edinilen tecrübeler.",
      ],
      softwareSkills: [
        {
          skillName: "Etkili Antrenmanlar",
          fontAwesomeClassname: "simple-icons:rumble",
          style: {
            color: "#85C742",
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Sese Özel Eğitim",
          fontAwesomeClassname: "simple-icons:microdotblog",
          style: {
            color: "#FF8800",
            backgroundColor: "transparent",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "9 Eylül Üniversitesi",
      subtitle: "Almanca Öğretmenliği",
      logo_path: "dal.png",
      alt_name: "DAL",
      duration: "2019 - 2024",
      descriptions: [
        "⚡ Profesyonel öğretim teknikleri",
        "⚡ Okurken bir yandan Almanca ve İngilizce özel dersleri",
        "⚡ Çeşitli okullarda binlerce öğrenciyle yapılan stajlar.",
      ],
      website_link: "https://www.deu.edu.tr",
    },
    {
      title: "İzmir Adnan Menderes Anadolu Lisesi ",
      subtitle: "Yabancı Dil Bölümü",
      logo_path: "sal.png",
      alt_name: "SETI",
      duration: "2014 - 2018",
      descriptions: [
        "⚡ Dil öğrenme ve geliştirme isteğimin temellerinin atıldığı yıllar.",
        "⚡ Çeşitli Dil Kursları",
        "⚡ Kendimi dil alanında hep geliştirdim.",
      ],
      website_link: "https://admen.meb.k12.tr/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "AWS Developer Associate",
      subtitle: "Amazon Web Services",
      logo_path: "aws.png",
      certificate_link:
        "https://www.credly.com/badges/b12fbece-07f3-47f5-9dda-cd56f49e250d/public_url",
      alt_name: "AWS",
      color_code: "#ffc475",
    },
    {
      title: "AWS Certified Cloud Practioner",
      subtitle: "Amazon Web Services",
      logo_path: "aws.png",
      certificate_link:
        "https://www.credly.com/badges/8b2db191-702d-427c-91aa-0f926be447a6/public_url",
      alt_name: "AWS",
      color_code: "#ffc475",
    },
    {
      title: "Google Summer of Code 2019",
      subtitle: "Google / Sugar Labs",
      logo_path: "google_logo.png",
      certificate_link: " ",
      alt_name: "Google",
      color_code: "#ffc475",
    },
    {
      title: "Google Code-In Student",
      subtitle: "2014-2017",
      logo_path: "google_logo.png",
      certificate_link: " ",
      alt_name: "Google",
      color_code: "#1e70c1",
    },
    {
      title: "Google Summer of Code Mentor",
      subtitle: "2017 / 2018 / 2020",
      logo_path: "google_logo.png",
      certificate_link: " ",
      alt_name: "Google",
      color_code: "#ffbfae",
    },
    {
      title: "Google Code-In Mentor",
      subtitle: "2017 / 2018 / 2019",
      logo_path: "google_logo.png",
      certificate_link: " ",
      alt_name: "Google",
      color_code: "#b190b0",
    },
    {
      title: "Deep Learning Specialization",
      subtitle: "deeplearning.ai",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://coursera.org/share/737a9587023c666b8e6cb303157aaeba",
      alt_name: "deeplearning.ai",
      color_code: "#47A048",
    },
    {
      title: "Sequence Models",
      subtitle: "deeplearning.ai",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/FM5AKEZA9NUY",
      alt_name: "deeplearning.ai",
      color_code: "#F6B808",
    },
    {
      title: "Convolutional Neural Networks",
      subtitle: "deeplearning.ai",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/U8BLDNUT9UUM",
      alt_name: "deeplearning.ai",
      color_code: "#2AAFED",
    },
    {
      title: "Structuring Machine Learning Projects",
      subtitle: "deeplearning.ai",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/YLC25SJQKH3Y",
      alt_name: "deeplearning.ai",
      color_code: "#E2405F",
    },
    {
      title: "Machine Learning",
      subtitle: "deeplearning.ai",
      logo_path: "stanford_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/records/72KY93DT82MP",
      alt_name: "Stanford University",
      color_code: "#8C151599",
    },
    {
      title: "Neural Networks and Deep Learning",
      subtitle: "deeplearning.ai",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/records/25JXRB2RWHRX",
      alt_name: "Google",
      color_code: "#7A7A7A",
    },
    {
      title: "Improving Deep Neural Networks",
      subtitle: "deeplearning.ai",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/records/PKR9M9LQ3JWC",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "Android Developer Nanodegree",
      subtitle: "Part of Google India Scholarship Program",
      logo_path: "100.png",
      certificate_link: "https://graduation.udacity.com/confirm/HLE7K5V3",
      alt_name: "Google",
      color_code: "#C5E2EE",
    },
    {
      title: "InOut 4.0 Winner #2",
      subtitle: "2017",
      logo_path: "ino.png",
      certificate_link: " ",
      alt_name: "InOut",
      color_code: "#fffbf3",
    },
  ],
};

// Experience Page
const experience = {
  title: "Deneyimim",
  subtitle: "İşler, Stajlar ve Çalışma Hayatım",
  description:
    "Bugüne kadar toplam iki stajda onlarca okul ve binlerce öğrenme görmek bana öğretme çeşitliliğini ve sayısız deneyim kattı. Daha öğrenciyken vermeye başladığım özel dersler ile de öğrencilerimle bir aile olarak herkesi memnun bir şekilde eğitimlerini tamamlamasını sağladım ve sayısız öğrenciyle severek ilgilendim.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Çalışma Hayatı",
      experiences: [
        {
          title: "Almanca Öğretmenliği",
          company: "",
          company_url: "",
          logo_path: "germanflag.jpg",
          duration: "Mayıs 2020 - Devam ediyor",
          location: "Online/İzmir",
          description:
            "Her yaştan öğrencime yaşa göre özel ders vererek kişiye en uygun şekilde ders veriyorum.",
          color: "#f10000",
        },
        {
          title: "İngilizce Öğretmenliği",
          company: "Amerikan Kültür",
          company_url:
            "https://www.amerikandiliedebiyati.com/?gad_source=1&gbraid=0AAAAADLdVa4H-VHd8zi0wiJ-wsGxOVOcN&gclid=Cj0KCQjwurS3BhCGARIsADdUH52SyGDyxFUN_XMTVp4aJuWOiiamu2OTYc3hd3B7jaTc75h53gJApQEaAjDNEALw_wcB",
          logo_path: "britishflag.jpg",
          duration: "May 2019 - Devam ediyor",
          location: "Online/İzmir",
          description:
            "Sınıflara verdiğim özel dersin yanı sıra yıllardır tutkum olan İngilizce dilinde sayısız özel ders de verdim.",
          color: "#2962FF",
        },
      ],
    },
    {
      title: "Staj Tecrübem",
      experiences: [
        {
          title: "İzmir Anadolu Lisesi",
          company: "Almanca Öğretmenliği Stajyeri",
          company_url: "",
          logo_path: "ial.png",
          duration: "",
          location: "",
          description:
            "Burada stajımı yaparken birçok öğrenciyle tanışıp sayısız tecrübe kattım.",
          color: "#ee3c26",
        },
        {
          title: "Vali Nevzat Ayaz Anadolu Lisesi",
          company: "Almanca Öğretmenliği Stajyeri",
          company_url: "",
          logo_path: "vna.jpg",
          duration: "",
          location: "",
          description:
            "Burada sayısız tecrübe edinip kendimi öğretme alanında geliştirdim.",
          color: "#0071C5",
        },
      ],
    },
    {
      title: "Çalışmalarım",
      experiences: [
        {
          title: "Almanca Özel Ders",
          company: " ",
          company_url: " ",
          logo_path: "germanflag.jpg",
          duration: "2020 - Devam Ediyor",
          location: " ",
          description: "Yıllardır birçok öğrenciye sayısız özel ders.",
          color: "#D83B01",
        },
        {
          title: "İngilizce Özel Ders",
          company: " ",
          company_url: " ",
          logo_path: "britishflag.jpg",
          duration: "2020 - Devam Ediyor",
          location: " ",
          description: "Yıllardır birçok öğrenciye sayısız özel ders.",
          color: "#D83B01",
        },
        {
          title: "Bass Gitaristlik",
          company: " ",
          company_url: " ",
          logo_path: "bassgitar.jpg",
          duration: "2020 - Devam Ediyor",
          location: " ",
          description: "Birçok ekipte görev alıp, dersler verdşm",
          color: "#D83B01",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Çalışmalarım",
  description:
    "Yıllardır özel dersler veriyorum. Şu anda da birçok öğrenciye aktif ders vermekteyim. Ayrıca devlet okullarında staj yaparak binlerce öğrenciyle tanıştım ve fazlaca deneyim kattım. Şu ana kadar birçok ekipte görev aldım ve hepsinde harika anılar biriktirdim. Onlarca sahnede görev aldım.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Bana Ulaşın",
    profile_image_path: "bugraals.jpg",
    description:
      "Bana aşağıda belirttiğim adreslerden 7/24 ulaşabilirsiniz." +
      "Bana ulaşın size özel planla hemen eğitimlerimize başlayalım.",
  },

  blogSection: {
    title: "İletişim Bilgileri",
    subtitle:
      "Instagram: @bugrasener2 \nTwitter: @bugrasener2\nLinkedIn: /in/bugrasener",
  },
};

const projects = {
  data: [
    {
      name: "CineScope",
      url: "https://github.com/Hrishi1999/CineScope",
      description: "An IMDB-like application.",
      languages: [
        {
          name: "React.js",
          iconifyClass: "logos-react",
        },
        {
          name: "TypeScript",
          iconifyClass: "devicon-typescript",
        },
        {
          name: "Node.js",
          iconifyClass: "devicon-nodejs",
        },
        {
          name: "Express.js",
          iconifyClass: "devicon-express",
        },
        {
          name: "MongoDB",
          iconifyClass: "logos-mongodb",
        },
      ],
    },
    {
      name: "Journalify",
      url: "https://play.google.com/store/apps/details?id=com.picle.journalify",
      description:
        "You personal journaling companion, goals & daily challenges! About 5k+ downloads on Play Store.",
      languages: [
        {
          name: "Flutter",
          iconifyClass: "logos-flutter",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
      ],
    },
    {
      name: "RecipesAPI",
      url: "https://rapidapi.com/Hrishi1999/api/recipesapi2",
      description: "Get recipes for hundreds and thousands of dishes",
      languages: [
        {
          name: "NodeJS",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      name: "opPortfolio",
      url: "https://github.com/Hrishi1999/opPortfolio",
      description: "(This) A clean and full customizable portfolio template.",
      languages: [
        {
          name: "ReactJS",
          iconifyClass: "logos-react",
        },
      ],
    },
    {
      name: "Cab Security System",
      url: " ",
      description:
        "Driver drowsiness detection, profanity detection, violence detection, SMS alerts, cab driver tracking.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Tensorflow",
          iconifyClass: "logos-tensorflow",
        },
        {
          name: "OpenCV",
          iconifyClass: "logos-opencv",
        },
      ],
    },
    {
      name: "Lensinator",
      url: " ",
      description:
        "Published on Play Store back in 2017, crossed 55K downloads. Performed object detection, image captioning, OCR, handwritten OCR, barcode and QR code scanning with product information.",
      languages: [
        {
          name: "JavaScript",
          iconifyClass: "logos-java",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
      ],
    },
    {
      name: "Doorbell.io",
      url: "https://github.com/Hrishi1999/Doorbell.io",
      description:
        "A sound-classifier webapp made with ReactJS + TensorflowJS.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "ReactJS",
          iconifyClass: "logos-react",
        },
        {
          name: "TensorflowJS",
          iconifyClass: "logos-tensorflow",
        },
      ],
    },
    {
      name: "Video Summarization",
      url: "https://github.com/Hrishi1999/Video-Summarization",
      description:
        "A video summarization webapp. A Flask-based backend which servers the Tensorflow model and a frontend made in ReactJS.",
      languages: [
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "ReactJS",
          iconifyClass: "logos-react",
        },
        {
          name: "Tensorflow",
          iconifyClass: "logos-tensorflow",
        },
      ],
    },
    {
      name: "Dashboard Activity",
      url: "https://github.com/Hrishi1999/Dashboard.activity",
      description:
        "Google Summer of Code project. A Dashboard activity for Sugar OS which tracks user activity so user can know where they use their most time and where they create most files.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      name: "Project Pickle",
      url: " ",
      description:
        "An app similar to Uber Eats but targeted for Gruhudhyogs (homemade pickles and products). Order management, live order tracking, rating and reviews system, etc.",
      languages: [
        {
          name: "Java",
          iconifyClass: "logos-java",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      name: "Project Health Card",
      url: "",
      description:
        "An aadhard-card like utility card which keeps track of all your health records suchs as: Medicines, Lab Reports, Recent Diseases, Allergies, etc.",
      languages: [
        {
          name: "JavaScript",
          iconifyClass: "logos-java",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
        {
          name: "ReactJS",
          iconifyClass: "logos-react",
        },
        {
          name: "MongoDB",
          iconifyClass: "logos-mongodb",
        },
      ],
    },
    {
      name: "Food Classifier",
      url: " ",
      description:
        "A food classifier app using tflite which classfier over 50 Indian Dishes.",
      languages: [
        {
          name: "Flutter",
          iconifyClass: "logos-flutter",
        },
        {
          name: "Dart",
          iconifyClass: "logos-dart",
        },
        {
          name: "Tensorflow",
          iconifyClass: "logos-tensorflow",
        },
        {
          name: "Jupyter Notebook",
          iconifyClass: "logos-jupyter",
        },
      ],
    },
    {
      name: "Image Clustering",
      url: " ",
      description:
        "A simple use of K-Means Clustering algorithm to sort images by automatically predicting number of classes. This can be used to clean an image dataset.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Jupyter Notebook",
          iconifyClass: "logos-jupyter",
        },
      ],
    },
    {
      name: "Antivirus",
      url: " ",
      description:
        "A project from 2012. Made an antivirus with realtime scanning. Implemented by using comparison of md5 hashes.",
      languages: [
        {
          name: "Visual Basic",
          iconifyClass: "logos-dotnet",
        },
      ],
    },
    {
      name: "Sugar on Windows",
      url: " ",
      description:
        "Run and setup Sugar OS to be able to run on Windows using Windows Subsystem for Linux",
      languages: [
        {
          name: "Visual Basic",
          iconifyClass: "logos-dotnet",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      name: "Object + Face Detection",
      url: " ",
      description: "2017 project. Object detection + accurate face detection.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Tensorflow",
          iconifyClass: "logos-tensorflow",
        },
        {
          name: "OpenCV",
          iconifyClass: "logos-opencv",
        },
      ],
    },
    {
      name: "Image Captioning with Visual Attention",
      url: " ",
      description: "Ongoing. Used MSCOCO 2014/2017 for image captioning.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Tensorflow",
          iconifyClass: "logos-tensorflow",
        },
        {
          name: "Jupyter Notebook",
          iconifyClass: "logos-jupyter",
        },
      ],
    },
    {
      name: "DalSocial",
      url: " ",
      description:
        "An Android app to meet new people in the university, organize and join events. ",
      languages: [
        {
          name: "Kotlin",
          iconifyClass: "simple-icons:kotlin",
          color: "#F18E33",
        },
        {
          name: "Android",
          iconifyClass: "simple-icons:android",
          color: "#3DDC84",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
      ],
    },
    {
      name: "HalifaxFoodie",
      url: " ",
      description:
        "CSCI5410 Project. A multi-client recipe app for Halifax Foodies.",
      languages: [
        {
          name: "ReactJS",
          iconifyClass: "logos-react",
        },
        {
          name: "AWS",
          iconifyClass: "logos-aws",
        },
        {
          name: "Google Cloud",
          iconifyClass: "logos-google-cloud",
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
