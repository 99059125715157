import React from "react";
import "./SocialMedia.css";
import { socialMediaLinks } from "../../portfolio";
export default function socialMedia() {
  return (
    <div className="social-media-div">
      <a
        href="https://wa.me/905396094237?text=Buradan bana ulaşarak detaylı bilgi alabilirsiniz"
        className="icon-button whatsapp"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-whatsapp"></i>
        <span></span>
      </a>
      <a
        href={socialMediaLinks.linkedin}
        className="icon-button linkedin"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-linkedin-in"></i>
        <span></span>
      </a>
      <a
        href={`mailto:${socialMediaLinks.gmail}`}
        className="icon-button google"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-google"></i>
        <span></span>
      </a>
      <a
        href={`tel:905396094237`}
        className="icon-button phone"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-phone"></i>
        <span></span>
      </a>
      <a
        href={socialMediaLinks.instagram}
        className="icon-button instagram"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-instagram"></i>
        <span></span>
      </a>
    </div>
  );
}
