import React from "react";
import Header from "../../components/header/Header";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import BlogsImg from "./BlogsImg";
import { Fade } from "react-reveal";
import "./ContactComponent.css";
import { greeting, contactPageData } from "../../portfolio.js";
import { style } from "glamor";

// Font Awesome importları
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

const ContactData = contactPageData.contactSection;
const blogSection = contactPageData.blogSection;

function Contact(props) {
  const theme = props.theme;

  const styles = style({
    backgroundColor: `${theme.accentBright}`,
    ":hover": {
      boxShadow: `0 5px 15px ${theme.accentBright}`,
    },
  });

  return (
    <div className="contact-main">
      <Header theme={theme} setTheme={props.setTheme} />
      <div className="basic-contact">
        <Fade bottom duration={1000} distance="40px">
          <div className="contact-heading-div">
            <div className="contact-heading-img-div">
              <img
                className="profile-pic"
                src={require(`../../assests/images/${ContactData["profile_image_path"]}`)}
                alt=""
              />
            </div>
            <div className="contact-heading-text-div">
              <h1
                className="contact-heading-text"
                style={{ color: theme.text }}
              >
                {ContactData["title"]}
              </h1>
              <p
                className="contact-header-detail-text subTitle"
                style={{ color: theme.secondaryText }}
              >
                {ContactData["description"]}
              </p>
              <p
                className="blog-header-detail-text subTitle"
                style={{ color: theme.secondaryText }}
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  style={{ marginRight: "10px" }}
                />
                <a
                  href="https://www.instagram.com/bugrasener2?igsh=MWM3ZWh0eW1uNnNveQ=="
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: theme.text }}
                >
                  @bugrasener2
                </a>
              </p>

              {/* Twitter */}

              {/* LinkedIn */}
              <p
                className="blog-header-detail-text subTitle"
                style={{ color: theme.secondaryText }}
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  style={{ marginRight: "10px" }}
                  flip
                />
                <a
                  href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQF0hy_dGkKwCwAAAZIbD4VYiZFccC159KJKiHs-kbfTPh7Ec64hX2xov8WljGAC54mxwyARefpm1xs_MacZDEuHAVPJIVSlB6nK0nGmPbf8aNP0tV59RxsVDuAJqH7LKBV5NIU=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fahmet-bu%25C4%259Fra-%25C5%259Fener-428a30310%3Futm_source%3Dshare%26utm_campaign%3Dshare_via%26utm_content%3Dprofile%26utm_medium%3Dios_app"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: theme.text }}
                >
                  LinkedIn: ahmetbugrasener
                </a>
              </p>

              {/* Mail */}
              <p
                className="blog-header-detail-text subTitle"
                style={{ color: theme.secondaryText }}
              >
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{ marginRight: "10px" }}
                />
                <a
                  href={`mailto:ahmetbugrasener0@gmail.com`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: theme.text }}
                >
                  Mail: ahmetbugrasener0@gmail.com
                </a>
              </p>

              {/* Telefon */}
              <p
                className="blog-header-detail-text subTitle"
                style={{ color: theme.secondaryText }}
              >
                <FontAwesomeIcon
                  icon={faPhone}
                  style={{ marginRight: "10px" }}
                  shake
                />
                <a
                  href={`tel:905396094237`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: theme.text }}
                >
                  Telefon: 0539 609 4237
                </a>
              </p>
              <SocialMedia />
              <br />
              <br />
            </div>
          </div>
        </Fade>

        <Fade bottom duration={1000} distance="40px">
          <div className="blog-heading-div">
            <div className="blog-heading-text-div"></div>
            <div className="blog-heading-img-div">
              <BlogsImg theme={theme} />
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}

export default Contact;
