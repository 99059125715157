import React from "react";

export default function CloudInfraImg(props) {
  const theme = props.theme;
  return (
    <svg
      id="1dff4cb4-53bd-4ffc-acc1-5e8a2ceb72de"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox="0 0 864.81 658.45"
    >
      <defs>
        <linearGradient
          id="e6219774-622b-406f-ae45-f26a74b1f5cd"
          x1="477.09"
          y1="779.22"
          x2="477.09"
          y2="120.78"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="gray" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
          <stop offset="1" stopColor="gray" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="2394db48-9094-48a1-80b4-38413320a0c3"
          x1="309.42"
          y1="623"
          x2="309.42"
          y2="461"
          // xlink:href="#e6219774-622b-406f-ae45-f26a74b1f5cd"
        />
        <linearGradient
          id="894cb520-7283-4f65-92a7-edb841614828"
          x1="773.42"
          y1="549"
          x2="773.42"
          y2="387"
          // xlink:href="#e6219774-622b-406f-ae45-f26a74b1f5cd"
        />
        <linearGradient
          id="43bfcda2-dcfb-4411-a28b-16db327cb63c"
          x1="664.42"
          y1="277"
          x2="664.42"
          y2="115"
          // xlink:href="#e6219774-622b-406f-ae45-f26a74b1f5cd"
        />
      </defs>
      <title></title>
      <g opacity="0.7">
        <path
          d="M506.4,473.94l-.59-.14V451.2a120.23,120.23,0,0,0,89.79-97.09l.29,0c5.77,0,10.44-8.77,10.44-19.58,0-6.71-1.8-12.62-4.54-16.15q.62-6.2.63-12.57a125,125,0,0,0-38.62-90.49,80.54,80.54,0,0,0-8.87-23.81c-8.9-15.68-23.71-32-17.67-49l-39.1,4-.36-25.76c-12.2,2.19-17.31,16.33-24.55,26.4-17.41,24.22-52.35,27.59-75.46,46.45-7.85,6.41-25.51,25.9-22.19,38.68a124.75,124.75,0,0,0-23.84,73.53q0,6.36.63,12.57c-2.74,3.53-4.54,9.44-4.54,16.15,0,10.82,4.68,19.58,10.44,19.58l.29,0a120.23,120.23,0,0,0,89.79,97.09v22.6l-.59.14C434.32,472.37,298.48,462,328.27,685.4l18.27,67.36,4.07-.16A28.72,28.72,0,0,0,407,757.71c25.87-.09,55.09-.17,70-.17s44.18.09,70,.17a28.72,28.72,0,0,0,56.44-5.11l4.07.16,18.27-67.36C655.71,462,519.86,472.37,506.4,473.94Z"
          transform="translate(-167.6 -120.78)"
          fill="url(#e6219774-622b-406f-ae45-f26a74b1f5cd)"
        />
      </g>
      <rect y="625.16" width="645.61" height="21.88" fill={theme.imageDark} />
      <rect
        x="27.36"
        y="647.05"
        width="590.89"
        height="8.95"
        fill={theme.imageDark}
      />
      <rect x="27.36" y="647.05" width="590.89" height="2.98" opacity="0.1" />
      <path
        d="M361,747.61s86.63-.34,116.09-.34,116.09.34,116.09.34V735H361Z"
        transform="translate(-167.6 -120.78)"
        opacity="0.1"
      />
      <path
        d="M593.19,745.08s-86.63-.34-116.09-.34-116.09.34-116.09.34V585.74A116.09,116.09,0,0,1,477.09,469.65h0A116.09,116.09,0,0,1,593.19,585.74Z"
        transform="translate(-167.6 -120.78)"
        fill={theme.accentColor}
      />
      <path
        d="M298.5,290.83h22a16.76,16.76,0,0,1,16.76,16.76v46.33a27.76,27.76,0,0,1-27.76,27.76h0a27.76,27.76,0,0,1-27.76-27.76V307.59A16.76,16.76,0,0,1,298.5,290.83Z"
        fill={theme.skinColor}
      />
      <path
        d="M449.33,453.67a116.17,116.17,0,0,0,55.52,0V414.13H449.33Z"
        transform="translate(-167.6 -120.78)"
        opacity="0.1"
      />
      <circle cx="309.5" cy="189.88" r="121.14" fill={theme.dark} />
      <circle cx="309.5" cy="217.64" r="116.09" fill={theme.skinColor} />
      <path
        d="M400.44,202.19c22.33-18.23,56.11-21.48,72.94-44.9,7-9.73,11.93-23.4,23.73-25.52l.35,24.9,37.79-3.88c-5.83,16.42,8.48,32.23,17.08,47.38a77.93,77.93,0,0,1,10.09,36.68c-13.12,7.88-29.39-.72-44-5.31-14.2-4.46-29.29-4.92-44.17-5.34-7.63-.22-15.35-.42-22.8,1.25-6.53,1.47-12.64,4.34-18.82,6.93-10.05,4.21-41.79,20.09-51.13,9.85C370.92,232.62,391.82,209.23,400.44,202.19Z"
        transform="translate(-167.6 -120.78)"
        fill={theme.dark}
      />
      <path
        d="M406.75,280.2c6.16,3.73,13.4,5.2,20.53,6.18a180.86,180.86,0,0,0,88.11-9.9c4.6,11.66,15.83,19.67,27.77,23.48s24.69,4,37.22,4.15c2.76,0,5.72,0,8-1.53,5.58-3.77,2.88-12.4-.37-18.3L571.84,255c-4.78-8.66-9.7-17.5-17.1-24.06-9.28-8.21-21.6-12.06-33.75-14.49a190.34,190.34,0,0,0-86.64,2.89C404.13,227.47,365.31,255.12,406.75,280.2Z"
        transform="translate(-167.6 -120.78)"
        opacity="0.1"
      />
      <path
        d="M406.75,277.68c6.16,3.73,13.4,5.2,20.53,6.18a180.86,180.86,0,0,0,88.11-9.9c4.6,11.66,15.83,19.67,27.77,23.48s24.69,4,37.22,4.15c2.76,0,5.72,0,8-1.53,5.58-3.77,2.88-12.4-.37-18.3l-16.17-29.32c-4.78-8.66-9.7-17.5-17.1-24.06-9.28-8.21-21.6-12.06-33.75-14.49a190.34,190.34,0,0,0-86.64,2.89C404.13,224.94,365.31,252.59,406.75,277.68Z"
        transform="translate(-167.6 -120.78)"
        fill={theme.dark}
      />
      <ellipse
        cx="424.33"
        cy="217.64"
        rx="10.1"
        ry="18.93"
        fill={theme.skinColor}
      />
      <ellipse
        cx="194.67"
        cy="217.64"
        rx="10.1"
        ry="18.93"
        fill={theme.skinColor}
      />
      <path
        d="M504.39,473.27S651.23,450.4,620.95,677.54l-17.66,65.11-50.48-2s32.81-75.71,12.62-126.19S504.39,473.27,504.39,473.27Z"
        transform="translate(-167.6 -120.78)"
        fill={theme.accentColor}
      />
      <path
        d="M449.8,473.27S303,450.4,333.24,677.54l17.66,65.11,50.48-2s-32.81-75.71-12.62-126.19S449.8,473.27,449.8,473.27Z"
        transform="translate(-167.6 -120.78)"
        fill={theme.accentColor}
      />
      <path
        d="M386.24,573.36s-10.1,78,7.57,100.71"
        transform="translate(-167.6 -120.78)"
        opacity="0.1"
      />
      <path
        d="M567.95,575.36s10.1,76-7.57,98.71"
        transform="translate(-167.6 -120.78)"
        opacity="0.1"
      />
      <circle cx="404.08" cy="619.68" r="27.76" fill={theme.skinColor} />
      <circle cx="214.91" cy="619.68" r="27.76" fill={theme.skinColor} />
      <g opacity="0.7">
        <rect
          x="218.04"
          y="461"
          width="182.77"
          height="162"
          rx="21.34"
          ry="21.34"
          fill="url(#2394db48-9094-48a1-80b4-38413320a0c3)"
        />
      </g>
      <rect
        x="221.42"
        y="464"
        width="176"
        height="156"
        rx="21.34"
        ry="21.34"
        fill={theme.imageDark}
      />
    </svg>
  );
}
